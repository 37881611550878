<template>
  <FormulateForm
    ref="form"
    v-model="payload"
    class="sign-in-form fluid v-spacing-3"
    @input="validate"
    @submit="onSubmit"
  >
    <FormulateInput
      type="email"
      :placeholder="$t(`${$options.path}.email`)"
      name="email"
      autocomplete="username"
      validation="bail|email|required"
    />
    <FormulateInput
      type="password"
      :placeholder="$t(`${$options.path}.password`)"
      name="password"
      autocomplete="current-password"
      validation="required"
      :validation-name="$t(`${$options.path}.password`)"
    />

    <router-link
      :to="{ name: 'send-reset-password', query: { email: payload.email } }"
      class="d-block text-align-center h-margin-center"
    >
      <t u>{{ $t(`${$options.path}.recover-password`) }}</t>
    </router-link>

    <AsyncMessage
      :fetching="fetching"
      :message="
        response.error && $t(`errors.${response.error.code || 'default'}`)
      "
    />

    <FormulateInput type="submit" :disabled="hasErrors">
      <EButton :disabled="hasErrors" filled lg>
        {{ $t(`${$options.path}.action`) }}
      </EButton>
    </FormulateInput>
  </FormulateForm>
</template>

<script>
import { mapActions } from 'vuex';

// components
import AsyncMessage from '@/authentication/components/AsyncMessage.vue';

export default {
  name: 'SignInForm',
  components: { AsyncMessage },
  data() {
    return {
      payload: { email: '', password: '' },
      hasErrors: true,

      response: { data: null, error: null },
      fetching: false,
    };
  },
  methods: {
    ...mapActions('authentication', ['signIn']),

    async validate() {
      const { form } = this.$refs;
      if (!form) return;

      this.hasErrors = await form.hasValidationErrors();
    },

    async onSubmit() {
      try {
        this.fetching = true;

        const data = await this.signIn(this.payload);
        this.response.data = data;

        this.$emit('success');
      } catch (error) {
        this.response.error = error;

        this.$emit('failure', error);

        throw error;
      } finally {
        this.fetching = false;
      }
    },
  },

  path: 'c.sign-in-form',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
