import { render, staticRenderFns } from "./AsyncMessage.vue?vue&type=template&id=31bbd8c0&functional=true&"
import script from "./AsyncMessage.vue?vue&type=script&lang=js&"
export * from "./AsyncMessage.vue?vue&type=script&lang=js&"
import style0 from "./AsyncMessage.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports