<template>
  <div class="sign-in">
    <OddMessage :title="$t(`${$options.path}.title`)" />

    <SignInFrom class="mt-4" @success="onSuccess" />
  </div>
</template>

<script>
import { getDefaultRoute } from '@/router';

// components
// - forms
import SignInFrom from '@/authentication/forms/SignInForm.vue';
// - messages
import OddMessage from '@/components/utils/Message/OddMessage.vue';

export default {
  name: 'ResetPassword',
  components: { OddMessage, SignInFrom },
  methods: {
    onSuccess() {
      this.$router.push(getDefaultRoute()).catch(() => {});
    },
  },

  path: 'c.sign-in',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
