<template functional>
  <div v-if="props.fetching || props.message" class="async-message">
    <transition name="fade-normal" mode="out-in">
      <Progress v-if="props.fetching" color="secondary" />

      <t v-else-if="props.message" color="red" sm center>{{ props.message }}</t>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AsyncMessage',
  props: {
    fetching: { type: Boolean, required: true },
    message: { type: String, default: undefined },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$progress-circular-size: 32px

.async-message
  min-height: $progress-circular-size

  +d-flex-r(center, center)

  & > .progress-circular
    --size: #{$progress-circular-size}
</style>
