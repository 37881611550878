<template>
  <div class="odd-message">
    <ELogo class="mb-3" />

    <t v-if="title" :color="titleColor" lg bold center class="mb-3">
      {{ title }}
    </t>
    <t v-if="message" :color="messageColor" sm medium center>{{ message }}</t>
  </div>
</template>

<script>
// components
import { ELogo } from 'odd-ds';

export default {
  name: 'OddMessage',
  components: { ELogo },
  props: {
    title: { type: String, default: undefined },
    message: { type: String, default: undefined },

    titleColor: { type: String, default: 'secondary' },
    messageColor: { type: String, default: 'gray-2' },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$logo-max-width: 96px
$logo-min-width: 64px
$logo-width: 50%

.odd-message
  +d-flex-c(center, center)
  +h-margin(auto)

.odd-message > .e-logo
  max-width: $logo-max-width
  min-width: $logo-min-width
  width: $logo-width
  height: auto
</style>
