<template>
  <div class="v-sign-in authentication-view">
    <div class="v-sign-in__body">
      <portal to="app">
        <transition name="fade-out" mode="out-in">
          <Progress
            v-if="!ready || isAuthenticated"
            variant="odd"
            with-overlay="background"
          />
        </transition>
      </portal>

      <SignIn />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getDefaultRoute } from '@/router';

// components
import SignIn from '@/authentication/components/SignIn.vue';

export default {
  name: 'VSignIn',
  components: { SignIn },
  computed: {
    ...mapState(['ready']),
    ...mapGetters('authentication', ['isAuthenticated']),
  },
  watch: {
    isAuthenticated: {
      handler(current, previous) {
        if (current && !previous)
          this.$router
            .replace(this.$route.query.redirect || getDefaultRoute())
            .catch(() => {});
      },
      immediate: true,
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
